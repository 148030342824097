import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSeedling,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AssoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      plaquette: file(relativePath: { eq: "plaquette_asso.jpg" }) {
        childImageSharp {
          fixed(quality: 95, width: 376, height: 337) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="L'association Agro Bio Conso" />
      <section className="hero pattern-zigzag-light" style={{marginTop: -4}}>
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title dreamland is-size-2">
                L'association Agro Bio Conso
              </h1>
            </div>
          </div>
        </section>
        <section>
          <div className="container pt-5 pl-5 pr-5 has-text-justified">
            <p className="has-text-centered is-size-5" style={{fontStyle: "italic"}}>Agro Bio Conso est une fédération de groupes autonomes de consommateurs de produits biologiques créée en 2004.<br/> Voir les <Link to="/statuts">statuts de l'association</Link>.</p>
            <p className="has-text-centered has-text-weight-bold is-size-5">Elle repose sur le bénévolat : aucun frais ne grève la relation producteur / consommateur.</p>
            <div className="mt-5 columns is-vcentered">
              <div className="column has-text-centered">
                <Img fixed={data.plaquette.childImageSharp.fixed} alt="Le bio au juste prix" />
              </div>
              <div className="column">
                <h2 className="almond mb-4 is-size-3">Nos objectifs</h2>
                <ul>
                  <li>Permettre aux producteurs et aux consommateurs de se réapproprier le contrôle de leur pouvoir économique en organisant eux-mêmes un réseau de distribution de produits bio,</li>
                  <li>Organiser un commerce équitable local en circuit court, en invitant des producteurs bio à créer une activité commerciale complémentaire qui optimise leurs revenus,</li>
                  <li>Organiser des échanges entre producteurs, Permettre aux consommateurs de manger bio au juste prix,</li>
                  <li>Développer ce réseau dans toute la France en mettant gratuitement à disposition des producteurs et des consommateurs le site Internet pour qu'ils créent des groupes autonomes locaux.</li>
                </ul>
              </div>
            </div>
            <div className="columns is-vcentered mt-5 pt-5 pb-5 presentation">
              <div className="column">
                <div className="has-text-centered" style={{padding: "1rem 1.5rem", color: "#fff", background: "#4d9f16", borderRadius: 3}}>
                  <div className="columns is-vcentered">
                    <div className="column is-3 has-text-centered">
                      <FontAwesomeIcon icon={faSeedling} style={{fontSize: "7rem"}} />
                    </div>
                    <div className="column">
                      <div className="has-text-centered almond is-size-3">L'association Agro Bio Conso est</div>
                      <p>Initiateur du concept</p>
                      <p>et propriétaire du site Internet<br />mis <strong>gratuitement</strong> à disposition<br />des producteurs partenaires</p>
                    </div>
                  </div>
                </div>
                <div className="has-text-centered mt-5" style={{padding: "1rem 1.5rem", color: "#fff", background: "#d05e26", borderRadius: 3}}>
                  <div className="has-text-centered almond is-size-3">Les producteurs partenaires</div>
                  <p>Vendent <strong>leurs productions,</strong></p>
                  <p>Commercialisent des <strong>produits complémentaires</strong><br />achetés à d’autres producteurs locaux ou fournisseurs,</p>
                  <p><strong>Livrent</strong> les consommateurs adhérents dans les points de dépôt</p>
                </div>  
              </div>
              <div className="column">
                <div className="has-text-centered" style={{padding: "1rem 1.5rem", color: "#fff", background: "#3287b0", borderRadius: 3}}>
                  <div className="has-text-centered almond is-size-3">Les adhérents de l’association<br/>sont clients des producteurs partenaires</div>
                  <div className="has-text-centered almond is-size-3"></div>
                  <p>Ils <strong>commandent</strong> sur le site Internet,<br />Règlent directement les producteurs par chèque, virement, carte bancaire,<br />Retirent leurs commandes aux points de dépôts,</p>
                  <p><strong>Cotisent</strong> annuellement à l'association (participation aux frais du site, etc.)</p>
                </div>
                <div className="has-text-centered mt-5" style={{padding: "1rem 1.5rem", color: "#fff", background: "#7b26d0", borderRadius: 3}}>
                  <div className="has-text-centered almond is-size-3">Les bénévoles</div>
                  <p>Mettent <strong>bénévolement</strong> à disposition des <strong>points de dépôt</strong></p>
                  <p>Gèrent le site Internet</p>
                </div>
              </div>
            </div>
            <h2 className="almond mb-4 is-size-3">Nourri-culture</h2>
            <h3 className="has-text-weight-bold is-size-5">Réflexion "Nourri-culturelle"</h3>
            <p>La part des dépenses des ménages consacrés à la nourriture varie, selon les critères retenus, entre 15 et 19%. Cette part a diminué fortement en valeur relative, ce qui est normal dans une société dans laquelle le niveau de vie augmente. La faible augmentation en valeur réelle de ces dépenses permet de considérer que l'alimentation n'est pas la priorité des consommateurs français, pourtant réputés fins gourmets. Pourquoi en sommes-nous arrivés là ?</p>
            <h3 className="has-text-weight-bold is-size-5">Des coûts qui n'ont pas de prix !</h3>
            <p>Le montant de ces dépenses ne tient pas compte des subventions qui dénaturent l'analyse. De plus, les subventions sont payées par les contribuables que sont surtout les consommateurs par l'intermédiaire de la TVA qui représente 45 % de la fiscalité française : Les subventions ne conduisent pas à un cadeau social aux plus modestes par la baisse du prix de leur alimentation, car ils payent, au travers de leurs autres consommations, l'éventuelle baisse due aux subventions !</p>
            <p>Il faut aussi constater que les subventions conduisent à une diminution systématique des prix des produits agricoles qui profite à l'industrie agroalimentaire. Autrement dit quand les paysans réclament des aides supplémentaires ils se battent, sous des formes discutables, pour subventionner les industries agroalimentaires qui les exploitent. Il faut encore préciser que ces aides sont attribuées de façon très inéquitables et que certains en profitent beaucoup plus que d'autres .... ce qui motive ceux qui perçoivent le plus à convaincre ceux qui perçoivent le moins à les accompagner dans les manifs !!!</p>
            <p>Ainsi les paysans ne sont plus des producteurs libres et responsables seulement soumis aux aléas climatiques. Ils sont devenus des moléiculteurs qui produisent de la matière première pour l'industrie. Comme toute industrie, l'agroalimentaire a besoin de molécules les moins chers possibles. Leurs qualités intrinsèques n'a qu'une importance secondaire : la transformation y pourvoira. Il suffira d'ajouter des adjuvants pour que le goût soit standard pour ne déplaire à personne, même s'il ne séduit pas vraiment non plus.</p>
            <h3 className="has-text-weight-bold is-size-5">De la responsabilité des associations de consommateurs</h3>
            <p>La diminution des prix de l'alimentation est aussi la conséquence de la demande pressante des grandes organisations de consommateurs.</p>
            <p>Début des années 90, L'IOCU (Organisation Internationale des Organisations de Consommateurs), et le BEUC (Bureau Européen des Organisations de Consommateurs) ont lancé à plusieurs reprises des appels en faveur d'une conclusion rapide des négociations commerciales de l'Uruguay Round pour que tous les pays acceptent le pré-accord de Blair House aux considérations que: " Les consommateurs européens paient très cher le protectionnisme de leur pays, notamment en matière agricole et textile. Ils ont particulièrement besoin qu'un accord soit trouvé. ... Les consommateurs bénéficieraient, avec l'extension des échanges commerciaux, de plus de choix, de prix plus bas. Un accès élargi aux marchés occidentaux offrirait aux pays en voie de développement un moyen de se procurer des devises étrangères, ce qui est vital pour leurs économies". Le libéralisme dévastateur qui en a découlé est à l'origine notamment de nombreuses crises alimentaires dont celle de la vache folle.</p>
            <p>En France les enquêtes prix des associations de consommateurs, réalisées presque exclusivement dans la grande distribution, invitaient les consommateurs à n'aborder l'alimentation qu'au travers des prix négligeant ainsi les autres aspects, notamment les qualités liées aux conditions de production écologique, économique et sociale. Ce faisant, elles ont contribué à favoriser cette même grande distribution à utiliser l'alimentation comme produit d'appel pour amener les consommateurs devant ses autres rayons.</p>
            <p>Cette diminution de la valeur relative des aliments a conduit à "dévaloriser" la nourriture que l'on gaspille volontiers alors que la famine sévit sur presque tous les continents : ce qui ne vaut pas cher n'a pas de valeur sociale. Ceux qui produisent des produits à faible valeur sociale n'ont, eux aussi, qu'une faible valeur (considération) sociale. C'est choquant mais c'est ainsi et ne concerne pas que les paysans ! En France, les métiers manuels sont très dévalorisés. Pourtant ce sont ceux qui produisent les services les plus indispensables. On ne peux ni se passer de paysans, ni de maçons, ni d'égoutiers, ni d'éboueurs ... Il vaut mieux être fonctionnaire au ministère de l'agriculture que paysan, alors que l'acteur est le paysan !</p>
            <p>Je suis toujours surpris de constater que les automobilistes achètent presque toujours l'huile la plus chère pour le moteur de leur bagnole qui a une "espérance de vie" de l'ordre de 7 ans et recherchent les prix moindres pour l'huile qu'ils destinent au fonctionnement de leur corps qui a une espérance de vie de plus de 70 ans !!!</p>
            <p>Il est encore à noter que pour les appareils, les tests des revues spécialisées mettent en exergue les performances les plus élevées alors que bien souvent ces performances, souvent coûteuses, ne sont pas nécessaires à la majorité des consommateurs.</p>
            <h3 className="has-text-weight-bold is-size-5">Plaidoyer "nourriculturel"</h3>
            <p>La publicité aidant, les aliments commercialisés sont, dans leur immense majorité, des aliments à "réchauffer" qui se sont substitués aux aliments à "cuisiner" qui étaient directement issus de l'agriculture. Dans ce contexte la valeur ajoutée s'est déplacée de l'agriculteur au marchand spéculateur qui n'est responsable de rien, les transnationales n'ayant de comptes à rendre qu'à leurs actionnaires : En 1980, la valeur ajoutée de l'agriculture était de 113,6 milliards, celle de l'industrie agroalimentaire de 78,87. En 1999, elles sont respectivement de 200,9 et 212,2. Pendant la même période, la population agricole est passée de 1 869 000 à 910 000.</p>
            <p>Or, les traditions culinaires sont l'expression de l'adaptation des femmes et des hommes à leur terroir et au climat : elles, ont depuis la nuit des temps, été réalisées en auto alimentation à partir des productions locales. Ce qui n'a pas empêché l'introduction de semences exotiques tel les pommes de terre, les haricots, le maïs ... Dans ce contexte les peuples disposaient de la première des indépendances, celle de la nourriture. Ils ont développé des traditions qui font encore, de moins en moins, l'objet de fiertés régionales et nationales.</p>
            <p>Mais cette "nourriculture" contrevient aux ambitions des fanatiques du "marché" qui veulent que tout soit à vendre "pour faire marcher le commerce" c'est à dire tirer un maximum de profit. Par la notion des "avantages comparatifs", summum de la rationalité économique libérale, les marchands organisent au niveau planétaire la spécialisation des productions aussi bien industrielles qu'agricoles, pour rendre chaque humain dépendant du "marché" y compris pour son besoin fondamental de nourriture. Ainsi, nous sommes devenus les otage de ces incontournables multinationales qui disposent désormais de l'arme alimentaire en contrôlant tout, de la fourche à la fourchette !</p>
            <p>L'industrialisation de l'alimentation a aussi d'autres conséquences négatives que nous pouvons déjà mesurer dans notre quotidien :</p>
            <ul>
              <li>La normalisation très poussée conduit à la diminution de la diversité alimentaire et à la banalisation des saveurs.</li>
              <li>Le développement des productions commerciales dans le tiers-monde a aggravé l'exploitation du système coloniale. Dans les pays du sud les cultures d'exportation ont remplacé les cultures vivrières, ce qui à accentué l'inégalité des échanges et les famines : pour acheter une protéine alimentaire il est nécessaire qu'ils en vendent plusieurs.</li>
              <li>si les produits de l'agro-industrie sont en général hygiéniques par leur conformité en présence de germes, ils ne sont pas forcément hygiéniques au sens de la diététique. Outre la présence résiduelles de divers "icides" aux dangers certains, les transformations et la conservation détruisent beaucoup de vitamines. Les ajouts, de sucre, sel et autre glutamate pour intensifier le goût et stimuler l'appétit, favorisent l'obésité que certains présentent d'ores et déjà comme un fléau mondial. Elle se développe non seulement aux Etats Unis, mais aussi dans les pays ou sévit la misère : elle concernerait 12% des chinois, 16% des chinoises, 30% des malaisiens, 23% des mexicains, 35% des égyptiens et 40% des koweïtiens ... selon une étude menée par l'Association américaine de l'obésité.</li>
            </ul>
            <p>Pour que les différents peuples du monde retrouvent leurs racines culturelles, leur fierté nationale et leur indépendance politique, il faut qu'ils accèdent à l'autonomie alimentaire. Il faut développer dans chaque région et dans chaque pays la notion de "nourriculture".</p>
            <p className="has-text-weight-bold">L'alimentation a un coût, elle doit avoir un prix que les consommateurs doivent payer pour qu'ils redonnent à cette base de la vie, de la santé et de la convivialité sa véritable valeur économique, sociale et culturelle.</p>
            <p className="has-text-right">Serge GRASS, ex. Vice président de l'UFC Que Choisir. </p>
          </div>
        </section>
    </Layout>
  )
}

export default AssoPage
